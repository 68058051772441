/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  find, get, isEmpty, map, difference, forEach,
  intersection, filter, includes, size, concat, times, last, head, cloneDeep, toNumber,
  some,
} from 'lodash';
import { Close } from '@material-ui/icons';
import {
  setCart, setBookingFlowPreviousStep,
  setAvailableTherapists, setAvailableTherapistsWithTimeSlots, setAnonymousCart,
} from '../../../Actions';
import CTAButton from '../../Shared/CTAButton';
import SessionLength from './SessionLength';
import ProviderGender from './ProviderGender';
import '../Assets/Styles/index.css';
import './Assets/Styles/ModalityStep.css';
import RecipientName from './RecipientName';
import {
  addCartProduct, createCart, createAddress, updateCart, updateCartProduct,
} from '../Shared/helpers';
import { decode } from '../Shared/encode';
import { defaultErrorMessage, getB2bInfo } from '../../../constants';
import { seBookingDetails } from '../../Shared/WebAnalytics';
import QuantityInput from './QuantityInput';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ctaDisabled: true,
      optionIds: [get(this.props, 'booking.currentOption.id', '')],
      clientName: get(this.props, 'client.first_name', 'Client'),
      client2: `${get(this.props, 'client.first_name', 'Client')}'s Guest`,
      quantity: 1,
      submitted: false,
      gender: '',
      session: '',
      price: '',
      memberPrice: '',
      raw_price: 0,
      raw_member_price: 0,
      hasGenderOptions: false,
      hasSessionOptions: false,
    };
    this.listAppointmentsByClient = this.listAppointmentsByClient.bind(this);
    this.submitOptionsAndNext = this.submitOptionsAndNext.bind(this);
    this.updateOptionsByCartProduct = this.updateOptionsByCartProduct.bind(this);
    this.updateCTAStatus = this.updateCTAStatus.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.checkRequiredOptionIds = this.checkRequiredOptionIds.bind(this);
    this.showRelevantPrices = this.showRelevantPrices.bind(this);
    this.isMassageType = this.isMassageType.bind(this);
    this.getModalityTitle = this.getModalityTitle.bind(this);
    this.getDuration = this.getDuration.bind(this);
    this.getGender = this.getGender.bind(this);
    this.formatTitle = this.formatTitle.bind(this);
    this.formatSubtitle = this.formatSubtitle.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.startAtAddress = this.startAtAddress.bind(this);
    this.startAtCart = this.startAtCart.bind(this);
    this.startAtCartProduct = this.startAtCartProduct.bind(this);
    this.setDefaultValues = this.setDefaultValues.bind(this);
    this.showRelevantPricesOldWay = this.showRelevantPricesOldWay.bind(this);
  }

  componentDidMount() {
    this.setDefaultValues();
  }

  setDefaultValues() {
    const tempArr = cloneDeep(this.state.optionIds);
    if (get(this.props, 'booking.product.slug', '') === 'massage') {
      const opts = filter(get(this.props, 'booking.currentOption.sub_product_options', []), (ci) => (ci.display_type === 'bubble'));
      this.setState({ hasGenderOptions: some(opts, (el) => (includes(el.slug, "_gender"))), hasSessionOptions: some(opts, (el) => (includes(el.slug, "_length"))) });

      if (!some(opts, (el) => (includes(el.slug, "_length")))) {
        let { price, member_price, raw_price, raw_member_price } = get(this.props, 'booking.currentOption', {});
        this.setState({
          price, memberPrice: member_price, raw_price, raw_member_price,
        })
      }
      if (!isEmpty(opts)) {
        forEach(opts, (optEl) => {
          let defaultOption = find(get(optEl, 'options', []), (opp) => (opp.default));
          if (!defaultOption) {
            defaultOption = head(get(optEl, 'options', []));
          }
          if (defaultOption) {
            tempArr.push(get(defaultOption, 'id', ''));
          }
        });
        this.setState({ optionIds: tempArr });
      }
    } else {
      const {
        raw_member_price, raw_price, price, memberPrice,
      } = get(this.props, 'booking.currentOption', {});
      this.setState({
        raw_member_price, raw_price, price, memberPrice,
      });
    }
    this.updateCTAStatus(true);
  }

  isMassageType() {
    return get(this.props, 'product.slug', '') === 'massage';
  }

  getModalityTitle() {
    return get(this.props, 'booking.currentOption.title');
  }

  getDuration() {
    if (this.isMassageType()) {
      return get(this.state, 'session', '');
    }
    return '';
  }

  getGender() {
    if (this.isMassageType()) {
      return get(this.state, 'gender', '');
    }
    return '';
  }

  formatTitle() {
    const title = get(this.props, 'booking.currentOption.title', '');
    if (this.isMassageType()) {
      return `${this.getDuration()} ${title}`;
    }
    return title;
  }

  formatSubtitle() {
    if (this.isMassageType() && this.getGender()) {
      return `with a ${this.getGender()} Provider`;
    }
    return '';
  }

  getPrice() {
    const opt = get(this.state, 'product.opt', null);
    if (this.isMassageType()) {
      const sessions = get(this.state, 'product.sessions', []);
      const lngOpt = find(sessions, (op) => (get(this.state, 'options.sessions', '') === op.service_id));
      const total = get(lngOpt, 'rawPrice', '') ? Number(get(lngOpt, 'rawPrice', '')) + Number(getB2bInfo('parking_fee')) : 0;
      return `${get(lngOpt, 'currency', '')}${total || ''}`;
    }
    const subT = get(opt, 'rawPrice', '') ? Number(get(opt, 'rawPrice', '')) + Number(getB2bInfo('parking_fee')) : '';
    return `${get(opt, 'currency', '')}${subT || ''}`;
  }

  errorHandler(err) {
    this.props.setLoaderFlag(false);
    this.setState({ submitted: false });
    this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
  }

  checkRequiredOptionIds(cpObj, cpId) {
    // TODO need to find another way to know the required fields,
    // since at certain point we can't use the prdId of a
    //  removed cart product (being created from scratch)
    const prdOpIds = map(get(this.props, 'booking.currentOption.sub_product_options', []), (pOpObj) => (pOpObj.id));
    const option_ids = get(cpObj, 'option_ids', []);
    const cp_info_fields = get(this.props, 'booking.cart.info_fields.cart_products', []);
    let prdOs = get(find(cp_info_fields, (ci) => (`${ci.id}` === cpId)), 'booking.currentOption.sub_product_options', []);
    if (!isEmpty(prdOs)) {
      prdOs = filter(prdOs, (p) => (includes(prdOpIds, p.id) && p.options.required));
      forEach(prdOs, (pOs) => {
        if (isEmpty(intersection(option_ids, pOs.options.allowed_values))) {
          this.setState({ ctaDisabled: true });
          return false;
        }
      });
    }
  }

  updateCTAStatus(skipp = false) {
    const prdId = get(this.props, 'product.id', '');
    const { optionIds, clientName, hasGenderOptions, hasSessionOptions } = this.state;
    let minimumSize = 3;
    if (!hasSessionOptions) {
      minimumSize -= 1;
    }
    // if (!hasGenderOptions) {
    //   minimumSize -= 1;
    // }

    this.setState({ ctaDisabled: false }, () => {
      if (prdId) {
        if (isEmpty(clientName) || isEmpty(optionIds) || (this.isMassageType() && ((size(optionIds) < minimumSize && !get(this.props, 'booking.cart.therapist_preferences.0.id', '')) || (size(optionIds) < minimumSize - 1 && get(this.props, 'booking.cart.therapist_preferences.0.id', ''))))) {
          this.setState({ ctaDisabled: true });
          return false;
        }
        this.setState({ ctaDisabled: false });
      }
      if (!skipp) {
        this.submitOptionsAndNext(true);
      }
    });
  }

  updateOptionsByCartProduct(optionId, allowedValues, required, title, field) {
    if (optionId) {
      const { optionIds } = this.state;
      const newArray = difference(optionIds, allowedValues);
      newArray.push(optionId);
      this.setState({ optionIds: newArray, [field]: title }, () => {
        if (required) {
          this.updateCTAStatus(true);
        }
      });
    }
  }

  startAtAddress() {
    const address = get(this.props, 'booking.cart.address', null);
    const cart = get(this.props, 'booking.cart', {});
    if (!isEmpty(address)) {
      if (!address.id) {
        createAddress(address, (resp) => {
          this.props.assignToCart({ cart: { ...cart, address: get(resp, 'data.address', null) } });
          this.startAtCart();
        }, this.errorHandler);
      } else {
        this.startAtCart();
      }
    }
  }

  startAtCart() {
    const cartId = get(this.props, 'booking.cart.id', '');
    const addressId = get(this.props, 'booking.cart.address.id', '');
    if (cartId) {
      updateCart(cartId, {
        address_id: addressId,
      }, get(this.props, 'fieldsHolder.csrfToken', ''), (response2) => {
        this.props.assignToCart({
          cart: decode(response2.data.cart),
          product: this.props.booking.product,
          addressId,
        });
        this.startAtCartProduct();
      }, this.errorHandler);
    } else {
      createCart(addressId, get(this.props, 'fieldsHolder.csrfToken', ''), (response2) => {
        this.props.assignToCart({
          cart: decode(response2.data.cart),
          product: this.props.booking.product,
          addressId,
        });
        this.startAtCartProduct();
      }, this.errorHandler);
    }
  }

  startAtCartProduct() {
    const cartId = get(this.props, 'booking.cart.id', '');
    const cartProducts = get(this.props, 'booking.cart.cartProducts', []);
    const {
      optionIds, clientName, quantity, client2,
    } = this.state;
    times(quantity, (ind) => {
      addCartProduct(cartId, {
        product_id: get(this.props, 'product.id'),
        client_name: ind === 0 ? clientName : client2,
      }, (respp) => {
        this.props.assignToCart({ cart: decode(respp.data.cart) });
        const newCartProducts = get(this.props, 'booking.cart.cartProducts', []);
        let relevantCartProduct = last(newCartProducts);
        if (ind + 1 !== quantity) {
          relevantCartProduct = head(newCartProducts);
        }
        updateCartProduct(cartId, get(relevantCartProduct, 'id', ''), {
          option_ids: optionIds,
        }, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
          this.props.assignToCart({ cart: decode(response.data.cart) });
          const cp = get(this.props, `booking.cart.cartProducts.${ind}`, {});
          seBookingDetails(decode(response.data.cart), cp, get(this.props, 'product.title', '').toLowerCase(), this.props.client.first_time_booker, 'booking_23_variant', size(cartProducts), get(cp, 'subtitle', ''), get(this.props, 'client.loggedIn', false), get(this.props, 'booking.cart.address.market', ''), "jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""));
          if (ind + 1 === quantity) {
            this.props.setLoaderFlag(false);
            this.props.setAvailableTherapists([]);
            this.props.setAvailableTherapistsWithTimeSlots({});
            this.props.closeDrawer();
            this.props.showCart();
          }
        }, this.errorHandler);
      }, this.errorHandler);
    });
  }

  submitOptionsAndNext(skipNext = false) {
    if (!skipNext) {
      this.props.setLoaderFlag(true);
      this.setState({ submitted: true });
    }
    const {
      optionIds, clientName, quantity, client2, price,
    } = this.state;
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const anonCart = get(this.props, 'anonymousCart', {});
    let cps = get(anonCart, 'cartProducts', []);
    const market = get(this.props, 'anonymousCart.address.market', '') || get(this.props, 'booking.cart.address.market', '');
    if (clientName && !client2) {
      this.setState({ client2: `${clientName}'s Guest` });
    }
    if (!loggedIn) {
      if (size(cps) < 2) {
        const cp = {
          optionIds, clientName, title: this.formatTitle(), subtitle: this.formatSubtitle(), price,
        };
        const newArr = [cp];
        seBookingDetails(get(this.props, 'booking.cart', {}), cp, get(this.props, 'product.title', '').toLowerCase(), get(this.props, 'client.first_time_booker', null), 'booking_23_variant', size(cps), this.getGender(), loggedIn, market, "jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""));
        if (quantity === 2) {
          newArr.push({
            optionIds,
            clientName: client2,
            price,
            title: this.formatTitle(),
            subtitle: this.formatSubtitle(),
          });
        }
        cps = concat(cps, newArr);
      }
      this.props.setAnonymousCart({ ...anonCart, cartProducts: cps });
      this.props.setLoaderFlag(false);
      this.props.closeDrawer();
      this.props.showCart();
    } else {
      this.startAtAddress();
    }
  }

  listAppointmentsByClient() {
    const cart_products = get(this.props, 'booking.cart.cartProducts', []);
    const rebook = get(this.props, 'booking.cart.rebook', false);
    const option = get(this.props, 'booking.currentOption', {});
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const anoncrtPrds = get(this.props, 'anonymousCart.cartProducts', []);
    const isFirstCP = (size(cart_products) === 0 && loggedIn)
      || (!loggedIn && size(anoncrtPrds) === 0);
    const { clientName, optionIds } = this.state;
    return (
      <div className="mb-100 p-16">
        <div className="font-weight-bold size-24-32 contentPrimary mb-4">{get(option, 'title', '')}</div>
        <div className="size-14-20 contentSecondary mb-16">{get(option, 'long_description', '')}</div>
        {((size(cart_products) < 1 && loggedIn)
          || (!loggedIn && size(anoncrtPrds) < 1)) && !rebook
          ? (
            <QuantityInput
              quantity={this.state.quantity}
              updateQty={(quantity) => this.setState({ quantity })}
            />
          ) : null}
        <SessionLength
          optionIds={optionIds}
          isFirstCP={isFirstCP}
          updateOption={(
            optionId,
            allowedValues,
            required,
            title,
            prc,
            mmbrprc,
            raw_price,
            raw_member_price,
          ) => {
            this.setState({
              price: prc, memberPrice: mmbrprc, raw_price, raw_member_price,
            });
            this.updateOptionsByCartProduct(Number(optionId), allowedValues, required, title, 'session');
          }}
        />
        <ProviderGender
          optionIds={optionIds}
          isFirstCP={isFirstCP}
          updateOption={(optionId, allowedValues, required, title) => {
            this.updateOptionsByCartProduct(Number(optionId), allowedValues, required, title, 'gender');
          }}
        />
        <RecipientName
          defaultValue={clientName || ''}
          isFirstCP={isFirstCP}
          updateClientName={(field, clientName) => this.setState(
            { [field]: clientName },
            () => this.updateCTAStatus(true),
          )}
          quantity={this.state.quantity}
        />
      </div>
    );
  }

  showRelevantPrices() {
    const { price, quantity, raw_price } = this.state;
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const total = raw_price * quantity;
    const currSymbol = get(this.props, 'booking.cart.currency_symbol', '') || toNumber(head(price)) ? head(price) : '$';
    if (total === 0) {
      return '';
    }
    if (loggedIn) {
      const isSoothePassSubscribed = get(this.props, 'booking.cart.info_fields.soothe_pass.subscribed', false) || get(this.props, 'client.soothe_pass.soothe_pass_subscribed', false);
      if ((!isSoothePassSubscribed) && price) {
        return ` — ${currSymbol}${total}`;
      }
    } else if (price) {
      return ` — ${currSymbol}${total}`;
    }
    return '';
  }

  showRelevantPricesOldWay() {
    const {
      price, quantity, raw_member_price, raw_price,
    } = this.state;
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const total = raw_price * quantity;
    const memberTotal = raw_member_price * quantity;
    const currSymbol = get(this.props, 'booking.cart.currency_symbol', '') || toNumber(head(price)) ? head(price) : '$';
    if (loggedIn) {
      const isSoothePassSubscribed = get(this.props, 'booking.cart.info_fields.soothe_pass.subscribed', false) || get(this.props, 'client.soothe_pass.soothe_pass_subscribed', false);
      if (isSoothePassSubscribed) {
        return { content: (`${currSymbol}${memberTotal} Member`), direction: 'left', subContent: (`${currSymbol}${total} NonMember`) };
      }
    }
    return null;
  }

  render() {
    const { ctaDisabled } = this.state;
    const isSoothePassSubscribed = get(this.props, 'booking.cart.info_fields.soothe_pass.subscribed', false) || get(this.props, 'client.soothe_pass.soothe_pass_subscribed', false);

    return (
      <div className="width-464 sm-w-100">
        <div className="max-h-full-90 overflow-y-scroll">
          <div className="bg-cover height-320" style={{ backgroundImage: `url(${get(find(get(get(this.props, 'booking.currentOption', {}), 'images', []), (im) => (im.kind === 'card_display_image')), 'web_url')})` }}>
            <button
              type="button"
              className="btn contentPrimary br-rd-48 fixed-btn-shadow-simple bg-primary abs-top m-12 d-flex-only align-items-center w-h-48 justify-content-centered"
              onClick={this.props.closeDrawer}
            >
              <Close style={{ fontSize: '14px' }} />
            </button>
          </div>
          {this.listAppointmentsByClient()}
        </div>
        <CTAButton
          text={`Add to cart${this.showRelevantPrices()}`}
          disabled={ctaDisabled}
          addon={this.showRelevantPricesOldWay()}
          submitted={this.state.submitted}
          action={this.submitOptionsAndNext}
          forceLeft
          additionalClass={isSoothePassSubscribed ? 'width-auto' : 'full-width-btn'}
          additionalWrapperClass={isSoothePassSubscribed ? 'justify-content-spaced sm-display-flex' : ''}
          relevantId="continueButton"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  booking: state.booking,
  client: state.client,
  bookingFlow: state.bookingFlow,
  product: state.booking.product,
  // notice the above line, it allows us to read product directly from props.
  //   this line doesn't exist in all components . becareful
  anonymousCart: state.anonymousCart,
  fieldsHolder: state.fieldsHolder,
});

Index.propTypes = {
  booking: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  bookingFlow: PropTypes.object.isRequired,
  setCart: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  setCart,
  setBookingFlowPreviousStep,
  setAvailableTherapists,
  setAvailableTherapistsWithTimeSlots,
  setAnonymousCart,
})(Index);
